/**
 * Temporary workaround to check if a feature is enabled.
 *
 * @deprecated - use `preload` service instead, this will be removed at some point
 */
export default function isFeatureEnabled(feature: 'global_inventory') {
  try {
    const preload = document.getElementById('preload');
    if (preload) {
      const preloadData = JSON.parse(preload.textContent!);
      return preloadData.data[feature];
    }
  } catch (error) {
    console.error('Error parsing preload data', error);
  }
  return false;
}
