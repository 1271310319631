import { attr, belongsTo } from '@ember-data/model';
import { isBlank } from '@ember/utils';

import type { Extra } from 'ticketbooth/utils/extras';

import type InventoryModel from './inventory';
import LineItemModel, { ProductGroup } from './line-item';
import MoneyVoucherProductModel from './money-voucher';
import type { ProductPurchaseOptions } from './product';
import ProductModel from './product';

function isProductModel(
  productOrProductLineItem:
    | ProductLineItemModel
    | ProductModel
    | ProductPurchaseOptions
): productOrProductLineItem is ProductModel {
  return productOrProductLineItem instanceof ProductModel;
}

function isProductLineItemModel(
  productOrProductLineItem: ProductLineItemModel | ProductPurchaseOptions
): productOrProductLineItem is ProductLineItemModel {
  return productOrProductLineItem instanceof ProductLineItemModel;
}

export function productPurchaseOptionsFromProductLineItemOrProduct(
  productOrLineItem:
    | ProductLineItemModel
    | ProductModel
    | ProductPurchaseOptions
): ProductModel | ProductPurchaseOptions {
  if (isProductModel(productOrLineItem)) {
    return productOrLineItem;
  } else {
    if (isProductLineItemModel(productOrLineItem)) {
      const { eventId } = productOrLineItem;

      if (eventId) {
        return {
          product: productOrLineItem.product,
          event: {
            id: eventId,
            type: 'events'
          }
        };
      } else {
        return productOrLineItem.product;
      }
    } else {
      return productOrLineItem;
    }
  }
}
export default class ProductLineItemModel extends LineItemModel {
  @attr('boolean') agreed!: boolean | null;
  @attr('boolean') requiresAgreement!: boolean | null;
  @attr('string') readonly checkinCode!: string;
  @attr('string') readonly qrCodeImageLink!: string | null;
  @attr('string') eventHubContext!: string;
  @attr('string') eventHubContextCategory!: string;
  @attr('boolean') giftaidable!: boolean;
  @attr() extras!: Extra[] | null;

  @belongsTo('product', { polymorphic: true, async: false, inverse: null })
  product!: ProductModel;

  @belongsTo('inventory', { async: false, inverse: null })
  inventory!: InventoryModel | null;

  get allowPriceOverride(): boolean {
    if (this.product instanceof MoneyVoucherProductModel) {
      return this.product.allowPriceOverride;
    }

    return false;
  }

  get isDonation(): boolean {
    return this.product.isDonationProduct;
  }

  get comment(): string {
    return this.isDonation ? this.product.comment : '';
  }

  get itemCount() {
    return this.quantity;
  }

  get hasExtras() {
    return this.extras && this.extras.length > 0;
  }

  get googleAnalyticsSku(): string {
    return this.product.googleAnalyticsSku;
  }

  get googleAnalyticsName(): string {
    return this.product.googleAnalyticsName;
  }

  get googleAnalyticsCategory(): string {
    return this.product.googleAnalyticsCategory;
  }

  get isFutureGifted(): boolean {
    return this.product.isFutureGiftable && !isBlank(this.giftEmail);
  }

  createGroup(lineItem: ProductLineItemModel): ProductGroup {
    return new ProductGroup(lineItem, this.product);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'product-line-item': ProductLineItemModel;
  }
}
