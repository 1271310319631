/* import __COLOCATED_TEMPLATE__ from './load-events.hbs'; */
import Component from '@glimmer/component';
import formatISO from 'date-fns/formatISO';
import isSameDay from 'date-fns/isSameDay';
import { Machine, assign } from 'xstate';

import type { Store } from '@ember-data/store';
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';

import { useMachine } from 'ember-statecharts';

import { matchesState } from 'tangram/utils/statecharts';
import type EventModel from 'ticketbooth/models/event';
import type ShowModel from 'ticketbooth/models/show';
import type TicketboothErrorsService from 'ticketbooth/services/errors';
import type PreloadService from 'ticketbooth/services/preload';

interface ShowLoadEventsSignature {
  Args: {
    show: ShowModel;
    date: Date;
    onSuccess?: Function;
    pageSize?: number;
    selectedEvent?: EventModel;
    onSelectedEventAvailable?: Function;
  };
  Blocks: {
    default: [unknown];
  };
}

type Context = {
  records: WithMeta<EventModel[]>;
  page: number;
  recordsCount: null | number;
  selectedEvent?: EventModel;
};

type Schema = {
  states: {
    loading: {};
    success: {};
    error: {};
  };
};

type Events =
  | { type: 'SEARCH' | 'RELOAD' }
  | { type: 'UPDATE_SELECTED_EVENT'; selectedEvent: EventModel };

export default class ShowLoadEventsComponent extends Component<ShowLoadEventsSignature> {
  @service private store!: Store;
  @service private errors!: TicketboothErrorsService;
  @service private preload!: PreloadService;

  private pageSize: number = this.args.pageSize ?? 20;

  get dateQueryParams() {
    const { date } = this.args;
    if (date) {
      return {
        q: {
          from_date: formatISO(date, { representation: 'date' }),
          to_date: formatISO(date, { representation: 'date' })
        }
      };
    }
    return {};
  }

  statechart = useMachine<Context, Schema, Events | InvokedEvent, any, {}>(
    this,
    () => {
      return {
        machine: Machine<Context, Schema, Events | InvokedEvent>({
          initial: 'loading' as const,
          predictableActionArguments: true,
          context: {
            records: [],
            recordsCount: null,
            page: 0,
            selectedEvent: undefined
          },
          on: {
            UPDATE_SELECTED_EVENT: {
              actions: [
                assign({
                  // @ts-ignore-next-line
                  selectedEvent: (_context, { selectedEvent }) => selectedEvent
                })
              ]
            },
            RELOAD: {
              target: 'loading',
              cond: 'shouldReload',
              actions: assign({
                page: 0,
                records: [],
                recordsCount: null
              })
            }
          },
          states: {
            loading: {
              invoke: {
                src: 'search',
                onDone: [
                  // when we don't have selectedEvent in context we will
                  // transition to success as we don't need to wait for
                  // a specific event to be loaded
                  {
                    target: 'success',
                    cond: context => !context.selectedEvent,
                    actions: ['assignRecordsFromResponse']
                  },
                  // selectedEvent is not included in event.data -> continue
                  // loading loop
                  {
                    target: 'loading',
                    cond: (context, event) => {
                      return ![
                        ...context.records,
                        ...event.data.slice()
                      ].includes(context.selectedEvent);
                    },

                    // we need to update page here as well
                    actions: ['assignRecordsFromResponse']
                  },
                  // selectedEvent is included in event data - we can stop
                  // loading loop and schedule scroll
                  {
                    target: 'success',
                    cond: (context, event) => {
                      return event.data.slice().includes(context.selectedEvent);
                    },
                    actions: [
                      'assignRecordsFromResponse',
                      'scheduleSelectedEventAvailable'
                    ]
                  },
                  // we are continuing loading but have a selected event just
                  // assign records and don't schedule scroll
                  {
                    target: 'success',
                    actions: ['assignRecordsFromResponse']
                  }
                ],
                onError: 'error'
              }
            },
            success: {
              entry: 'onSuccess',
              on: {
                SEARCH: { target: 'loading', cond: 'moreRecordsAvailable' }
              }
            },
            error: {
              entry: 'logError',
              on: {
                SEARCH: { target: 'loading', cond: 'moreRecordsAvailable' }
              }
            }
          }
        })
          .withContext({
            records: [],
            recordsCount: null,
            page: 0,
            selectedEvent: this.args.selectedEvent
          })
          .withConfig({
            guards: {
              shouldReload: () =>
                !this.hasLoadedAllEvents ||
                !this.eventsAreForCurrentDate ||
                !this.eventsAreForCurrentShow,
              selectedEventNotIncludedInEventsList: (context, event: any) => {
                if (!context.selectedEvent) {
                  return false;
                }

                return ![...context.records, ...event.data.slice()].includes(
                  context.selectedEvent
                );
              },
              eventDataContainsSelectedEvent: (context, event: any) => {
                const { data } = event;

                if (data) {
                  return data.slice().includes(context.selectedEvent);
                }

                return false;
              },
              moreRecordsAvailable: context => {
                if (context.recordsCount) {
                  const moreRecordsAvailable =
                    context.recordsCount > context.page * this.pageSize;
                  return moreRecordsAvailable;
                } else {
                  return true;
                }
              }
            },
            services: {
              search: async context => {
                const include = [
                  'venue',
                  'suggested-products',
                  'suggested-products.default-attachment',
                  ...(this.preload.getValue('global_inventory')
                    ? ['suggested-products.account-inventory']
                    : [])
                ].join(',');
                return this.store.query('event', {
                  include,
                  filter: {
                    show: this.args.show.id,
                    ...this.dateQueryParams
                  },
                  page: {
                    limit: this.pageSize,
                    offset: context.page * this.pageSize
                  }
                });
              }
            },
            actions: {
              logError: (_context, event: InvokedEvent) =>
                this.errors.log(event.data),
              onSuccess: context => this.args.onSuccess?.(context.records),
              assignRecordsFromResponse: assign({
                // incremenent page as next request should request next page
                page: context => context.page + 1,
                records: (c, event: any) => [
                  ...c.records,
                  ...event.data.slice()
                ],
                recordsCount: (_, event) =>
                  event.data.meta['records-count'] ?? 0
              }),
              scheduleSelectedEventAvailable: () => {
                const { onSelectedEventAvailable } = this.args;
                if (onSelectedEventAvailable) {
                  //  @ts-ignore
                  scheduleOnce('afterRender', null, onSelectedEventAvailable);
                }
              }
            }
          }),
        update({ send, machine }) {
          send('UPDATE_SELECTED_EVENT', {
            selectedEvent: machine.context.selectedEvent
          });
        }
      };
    }
  );

  @matchesState('loading') isLoading!: boolean;
  @matchesState('success') isLoaded!: boolean;
  @matchesState('error') error!: boolean;

  get loadRecords() {
    return this.statechart;
  }

  get records() {
    return this.loadRecords.state?.context.records ?? [];
  }

  get recordsCount() {
    return this.loadRecords.state?.context.recordsCount ?? null;
  }

  get numberOfExpiredEvents(): number {
    return this.records.filter(r => !r.isWithinCutoffTime).length;
  }

  get events(): EventModel[] {
    return this.records.filter(r => r.isWithinCutoffTime);
  }

  get firstEvent(): EventModel | null {
    return this.events[0];
  }

  get someEventHasLowAvailability(): boolean {
    return this.events.some(e => e.lowAvailability);
  }

  get hasLoadedAllEvents(): boolean {
    return this.totalEvents === this.events.length + this.numberOfExpiredEvents;
  }

  get hasLoadedSomeEvents(): boolean {
    return !!this.totalEvents && this.totalEvents > 0;
  }

  get hasLoadedSelectedEvent(): boolean {
    if (!this.args.selectedEvent) {
      return this.hasLoadedSomeEvents;
    } else {
      return this.records.includes(this.args.selectedEvent);
    }
  }

  get eventsAreForCurrentDate(): boolean {
    const eventDate = this.events[0]?.dateTime;
    return eventDate === undefined
      ? false
      : isSameDay(eventDate, this.args.date);
  }

  get eventsAreForCurrentShow(): boolean {
    const show = this.events[0]?.show;
    return show === this.args.show;
  }

  get totalEvents(): number | null {
    if (this.recordsCount === null) return null;
    return this.recordsCount;
  }

  get visibleEvents(): number {
    return this.totalEvents ? this.totalEvents - this.numberOfExpiredEvents : 0;
  }

  @action
  loadMore() {
    this.loadRecords.send('SEARCH');
  }

  @action
  reload() {
    this.loadRecords.send('RELOAD');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Show::LoadEvents': typeof ShowLoadEventsComponent;
    'show/load-events': typeof ShowLoadEventsComponent;
  }
}
